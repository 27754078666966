::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #a0aec0;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

@media (max-width: 440px) {
  .emoji-mart {
    right: -6rem !important;
  }
}

.font-petrona {
  font-family: "Petrona", serif !important;
}

audio {
  width: 100% !important;
}

.react-tel-input {
  font-family: "Satoshi" !important;
}

.react-tel-input .flag-dropdown {
  border-color: #e2e8f0 !important;
}

.react-tel-input .form-control {
  height: 48px !important;
  font-size: 1rem !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  background: #edf2f7 !important;
  padding-right: 1rem;
  border-color: #edf2f7 !important;
}

.react-tel-input .country-list {
  text-align: left;
}

.react-tel-input .flag-dropdown {
  background: #edf2f7 !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  border-color: #edf2f7 !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:active {
  background: #edf2f7 !important;
}

.react-tel-input .country-list {
  font-family: Satoshi !important;
  border: 1px solid #f8f7f7 !important;
  border-radius: 0.5rem !important;
  box-shadow: none;
  top: 10 !important;
}

.react-tel-input .country-list .search {
  padding: 10px 0 6px 0px !important;
  width: 100%;
}

.react-tel-input .country-list .search-box {
  padding: 3px 8px 5px 0;
  width: 95%;
}

/* .PhoneInput {
	height: 48px;
	background: #edf2f7;
	max-width: 210px;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.5rem !important;
} */
/*
.PhoneInput:focus-within {
	border: 0.5px solid #3182ce;
} */

/* @media (max-width: 767px) {
	.PhoneInput {
		max-width: 100%;
	}
}


.PhoneInputInput {
	background: #edf2f7;
	height: 48px;
	outline: none;
} */

.h-test {
  height: 400px;
}

canvas,
.react-pdf__Page__svg,
.react-pdf__Page,
.react-pdf__Document {
  width: 100% !important;
  height: 100% !important;
}

video {
  border-radius: 10px;
}

vime-player.video,
.flip-countdown {
  font-family: Satoshi !important;
}

.flip-countdown {
  text-align: left !important;
}
