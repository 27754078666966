:root {
  letter-spacing: calc(-0.032em + 0.48px);
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern", "calt", "liga";
  font-family: Satoshi, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-display: swap;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Satoshi";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Satoshi Light"), local("Satoshi-Light"), url("/fonts/Satoshi-Light.woff2") format("woff2"),
    url("/fonts/Satoshi-Light.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Satoshi Regular"), local("Satoshi-Regular"), url("/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("/fonts/Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Satoshi Medium"), local("Satoshi-Medium"), url("/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("/fonts/Satoshi-Medium.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Satoshi Bold"), local("Satoshi-Bold"), url("/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("/fonts/Satoshi-Bold.woff") format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: local("Satoshi Black"), local("Satoshi-Black"), url("/fonts/Satoshi-Black.woff2") format("woff2"),
    url("/fonts/Satoshi-Black.woff") format("woff");
}

body {
  font-size: calc(14px + 0.14vw);
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body::before {
  content: "";
  height: 4px;
  width: 100vw;
  position: fixed;
  top: 0;
  background: var(--chakra-black);
  z-index: 2800;
}

.dark-mode {
  color: #ffffffaa;
}

::selection {
  background-color: #def1ff;
}

.dark-mode ::selection {
  background-color: #def1ff4d;
}

svg {
  stroke-width: 0;
  shape-rendering: geometricPrecision;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
}

thead tr[class*="css-"]:hover {
  background: none !important;
}

button {
  letter-spacing: inherit;
}

/* input[class*="css-"]:focus,
select[class*="css-"]:focus,
textarea[class*="css-"]:focus {
  box-shadow: 0 1px 3px #0000003f;
} */

span[id*="chakra-toast-manager-"] {
  padding: 1rem;
}
@keyframes glow {
  0% {
    background-color: #005ee9;
    box-shadow: 0 0 0 5px #005ee930;
  }
  50% {
    background-color: #005ee9;
    box-shadow: 0 0 0 10px #005ee920;
  }
  100% {
    background-color: #005ee9;
    box-shadow: 0 0 0 5px #005ee910;
  }
}

.glow__anim {
  border-radius: 50%;
  animation: glow 2s infinite;
}

.chakra-button.chakra-menu__menu-button + div {
  z-index: 2000;
}
