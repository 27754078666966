@charset "UTF-8";

/*
Trix 1.2.2
Copyright © 2019 Basecamp, LLC
http://trix-editor.org/*/
#trix-editor-top-level {
  width: 100%;
  position: relative;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

trix-editor {
  /* border: 1px solid #bbb; */
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 1rem;
  flex: 1;
  width: 100%;
  max-width: 60vmax;
  margin: 0 auto;
  background-color: #fff;
  padding: 1rem 3rem;
  font-size: 1.1em;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 3px rgba(0, 0, 0, 0.1); */
  outline: none;
  /* background: linear-gradient(to right, #fefefe, #f7f7f7); */
  order: 3;
}

trix-editor:empty:not(:focus)::before {
  color: #b7c1ce;
}

trix-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #eee;
  padding: 0 1rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.05);
  order: 1;
}

trix-toolbar > * {
  opacity: 0.25;
}

.is-focused trix-toolbar > * {
  opacity: 1;
}

#trix-fragments {
  order: 2;
  /* background: linear-gradient(to right, #fefefe, #f7f7f7); */
  width: 100%;
}

trix-toolbar * {
  box-sizing: border-box;
}

trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0.5rem 0;
}

trix-toolbar .trix-button-group {
  display: flex;
  /* margin-bottom: 10px; */
  /* border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px; */
}

trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 1.5vw;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0;
  }
}

trix-toolbar .trix-button-group-spacer {
  flex-grow: 1;
}

@media (max-device-width: 768px) {
  trix-editor {
    width: 90vw;
  }

  trix-toolbar .trix-button-group-spacer {
    display: none;
  }
}

trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #ddd; */
  border-radius: 0;
  background: transparent;
}

/* trix-toolbar .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
} */

trix-toolbar .trix-button.trix-active {
  background: #cccc;
  color: black;
}

trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125);
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    padding: 0 0.3em;
  }
}

trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 2.4em;
  border-radius: 3px;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw);
  }
}

trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%;
  }
}

trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1;
}

trix-toolbar .trix-button--icon::before {
  background-size: 18px;
}

trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125;
}

trix-toolbar .trix-button--icon-attach::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256,512c-88.2,0-160-71.8-160-160V122.7a16,16,0,1,1,32,0V352a128,128,0,0,0,256,0V117.3a85.4,85.4,0,0,0-170.7,0V330.7a42.7,42.7,0,0,0,85.4,0v-208a16,16,0,1,1,32,0v208a74.7,74.7,0,0,1-149.4,0V117.3C181.3,52.7,234,0,298.7,0S416,52.7,416,117.3V352C416,440.2,344.2,512,256,512Z'/%3E%3C/svg%3E");
  /* top: 8%;
  bottom: 4%; */
}

trix-toolbar .trix-button--icon-bold::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 426 426'%3E%3Ctitle%3Ebold%3C/title%3E%3Cpath d='M101.2,426a16,16,0,0,1-16-16V16a16,16,0,1,1,31.9,0V410A16,16,0,0,1,101.2,426Z'/%3E%3Cpath d='M253,218.3H106.5a16,16,0,1,1,0-31.9H253A77.2,77.2,0,0,0,253,32H58.6a16,16,0,1,1,0-32H253a109.2,109.2,0,0,1,0,218.3Z'/%3E%3Cpath d='M263.7,426H58.6a16,16,0,1,1,0-31.9H263.7a87.9,87.9,0,0,0,0-175.8H106.5a16,16,0,1,1,0-31.9H263.7A119.8,119.8,0,0,1,383.4,306.1C383.4,372.3,329.7,426,263.7,426Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-italic::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 426 426'%3E%3Ctitle%3Eitalic%3C/title%3E%3Cpath d='M197,426H122.5a16,16,0,1,1,0-31.9H197a16,16,0,1,1,0,31.9Z'/%3E%3Cpath d='M303.5,32H229a16,16,0,0,1,0-32h74.5a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M159.8,426a15.2,15.2,0,0,1-4.2-.6,15.8,15.8,0,0,1-11.2-19.5l106.4-394a16,16,0,1,1,30.9,8.3l-106.5,394A16,16,0,0,1,159.8,426Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-link::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 469.3 469.3'%3E%3Ctitle%3Elink%3C/title%3E%3Cpath d='M359.8,302.1a16,16,0,0,1-11.3-27.3l56.9-56.9a107.5,107.5,0,0,0,32-77c0-60-48.9-108.9-109-108.9a107.3,107.3,0,0,0-76.9,31.9l-56.9,56.9a16,16,0,0,1-22.7-22.6l56.9-56.9A139.3,139.3,0,0,1,328.4,0c77.7,0,140.9,63.2,140.9,140.9a139.1,139.1,0,0,1-41.4,99.7l-56.8,56.8A16,16,0,0,1,359.8,302.1Z'/%3E%3Cpath d='M140.9,469.3C63.2,469.3,0,406.1,0,328.4a139.3,139.3,0,0,1,41.4-99.7l56.8-56.8a16,16,0,0,1,22.6,22.7L64,251.4a107.1,107.1,0,0,0-32,77A109,109,0,0,0,140.9,437.3a107.4,107.4,0,0,0,77-31.9l56.9-56.9a16,16,0,0,1,22.6,22.7L240.6,428C214.2,454.7,178.8,469.3,140.9,469.3Z'/%3E%3Cpath d='M149.3,336A16,16,0,0,1,138,308.7L308.7,138a16.1,16.1,0,0,1,22.6,0,15.9,15.9,0,0,1,0,22.6L160.7,331.3A16.4,16.4,0,0,1,149.3,336Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-strike::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 426 426'%3E%3Ctitle%3Estrike%3C/title%3E%3Cpath d='M367.4,229H58.6a16,16,0,0,1,0-32H367.4a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M213,426c-70.5,0-127.8-51.4-127.8-114.5a16,16,0,1,1,31.9,0c0,45.5,43.1,82.6,95.9,82.6s95.9-37.1,95.9-82.6S265.8,229,213,229c-70.5,0-127.8-51.4-127.8-114.5S142.5,0,213,0,340.8,51.4,340.8,114.5a16,16,0,1,1-31.9,0C308.9,69,265.8,32,213,32s-95.9,37-95.9,82.5S160.2,197,213,197c70.5,0,127.8,51.4,127.8,114.5S283.5,426,213,426Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-quote::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 426 426'%3E%3Ctitle%3Equote%3C/title%3E%3Cpath d='M367.4,213H314.2a58.7,58.7,0,0,1-58.6-58.6V101.2a58.7,58.7,0,0,1,58.6-58.6h53.2A58.7,58.7,0,0,1,426,101.2v53.2A58.7,58.7,0,0,1,367.4,213ZM314.2,74.5a26.7,26.7,0,0,0-26.6,26.7v53.2a26.7,26.7,0,0,0,26.6,26.7h53.2a26.8,26.8,0,0,0,26.7-26.7V101.2a26.8,26.8,0,0,0-26.7-26.7Z'/%3E%3Cpath d='M303.5,383.4H271.6a16,16,0,1,1,0-31.9h31.9a90.7,90.7,0,0,0,90.6-90.6V154.4a16,16,0,1,1,31.9,0V260.9A122.6,122.6,0,0,1,303.5,383.4Z'/%3E%3Cpath d='M111.8,213H58.6A58.7,58.7,0,0,1,0,154.4V101.2A58.7,58.7,0,0,1,58.6,42.6h53.2a58.7,58.7,0,0,1,58.6,58.6v53.2A58.7,58.7,0,0,1,111.8,213ZM58.6,74.5A26.7,26.7,0,0,0,32,101.2v53.2a26.7,26.7,0,0,0,26.6,26.7h53.2a26.8,26.8,0,0,0,26.7-26.7V101.2a26.8,26.8,0,0,0-26.7-26.7Z'/%3E%3Cpath d='M47.9,383.4H16a16,16,0,1,1,0-31.9H47.9a90.7,90.7,0,0,0,90.6-90.6V154.4a16,16,0,1,1,31.9,0V260.9A122.6,122.6,0,0,1,47.9,383.4Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M192,469.7a16,16,0,0,1-16-16V59a16,16,0,0,1,32,0V453.7A16,16,0,0,1,192,469.7Z'/%3E%3Cpath d='M368,128.3a16,16,0,0,1-16-16V75H32v37.3a16,16,0,1,1-32,0V59A16,16,0,0,1,16,43H368a16,16,0,0,1,16,16v53.3A16,16,0,0,1,368,128.3Z'/%3E%3Cpath d='M240,469.7H144a16,16,0,0,1,0-32h96a16,16,0,1,1,0,32Z'/%3E%3Cpath d='M314.7,304.3a15.9,15.9,0,0,1-16-16V251a16,16,0,0,1,16-16H496a16,16,0,0,1,16,16v32a16,16,0,0,1-32,0V267H330.7v21.3A16,16,0,0,1,314.7,304.3Z'/%3E%3Cpath d='M405.3,469.7a16,16,0,0,1-16-16V251a16,16,0,1,1,32,0V453.7A16,16,0,0,1,405.3,469.7Z'/%3E%3Cpath d='M432,469.7H378.7a16,16,0,0,1,0-32H432a16,16,0,0,1,0,32Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-code::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Ctitle%3Ecode%3C/title%3E%3Cpath d='M202.7,469.7a15.2,15.2,0,0,1-4.2-.6,16,16,0,0,1-11.3-19.6L293.9,54.9a16,16,0,1,1,30.9,8.3L218.1,457.9A16,16,0,0,1,202.7,469.7Z'/%3E%3Cpath d='M154.7,405.7a15.9,15.9,0,0,1-11.1-4.5L4.9,267.9a16.1,16.1,0,0,1,0-23.1L143.6,111.5a16,16,0,0,1,22.6.4,16.2,16.2,0,0,1-.4,22.7L39.1,256.3,165.8,378.1a16.1,16.1,0,0,1-11.1,27.6Z'/%3E%3Cpath d='M357.3,405.7a16.1,16.1,0,0,1-11.1-27.6L472.9,256.3,346.2,134.6a16.1,16.1,0,0,1-.4-22.7,15.9,15.9,0,0,1,22.6-.4L507.1,244.8a16.1,16.1,0,0,1,0,23.1L368.4,401.2A15.9,15.9,0,0,1,357.3,405.7Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M53.3,138.7a53.4,53.4,0,1,1,53.4-53.4A53.4,53.4,0,0,1,53.3,138.7Zm0-74.7A21.4,21.4,0,1,0,74.7,85.3,21.4,21.4,0,0,0,53.3,64Z'/%3E%3Cpath d='M53.3,309.3A53.3,53.3,0,1,1,106.7,256,53.3,53.3,0,0,1,53.3,309.3Zm0-74.6A21.3,21.3,0,1,0,74.7,256,21.3,21.3,0,0,0,53.3,234.7Z'/%3E%3Cpath d='M53.3,480a53.4,53.4,0,1,1,53.4-53.3A53.3,53.3,0,0,1,53.3,480Zm0-74.7a21.4,21.4,0,1,0,21.4,21.4A21.4,21.4,0,0,0,53.3,405.3Z'/%3E%3Cpath d='M496,101.3H165.3a16,16,0,0,1,0-32H496a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M496,272H165.3a16,16,0,0,1,0-32H496a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M496,442.7H165.3a16,16,0,0,1,0-32H496a16,16,0,0,1,0,32Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-number-list::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 490 490'%3E%3Cpath d='M69.2,308.9H16a16,16,0,0,1-16-16V266.3A37.3,37.3,0,0,1,37.3,229H47.9a5.4,5.4,0,0,0,5.4-5.3v-5.3a5.4,5.4,0,0,0-5.4-5.4H16a16,16,0,1,1,0-31.9H47.9a37.3,37.3,0,0,1,37.3,37.3v5.3A37.3,37.3,0,0,1,47.9,261H37.3a5.3,5.3,0,0,0-5.3,5.3V277H69.2a16,16,0,1,1,0,31.9Z'/%3E%3Cpath d='M47.9,431.4H26.6a16,16,0,0,1,0-31.9H47.9a5.4,5.4,0,0,0,5.4-5.4v-5.3a5.4,5.4,0,0,0-5.4-5.3H16a16,16,0,0,1,0-32H47.9a37.3,37.3,0,0,1,37.3,37.3v5.3A37.3,37.3,0,0,1,47.9,431.4Z'/%3E%3Cpath d='M47.9,479.3H16a16,16,0,1,1,0-31.9H47.9a5.4,5.4,0,0,0,5.4-5.3v-5.4a5.4,5.4,0,0,0-5.4-5.3H26.6a16,16,0,0,1,0-31.9H47.9a37.3,37.3,0,0,1,37.3,37.2v5.4A37.3,37.3,0,0,1,47.9,479.3Z'/%3E%3Cpath d='M47.9,138.5a16,16,0,0,1-15.9-16V42.6H16a16,16,0,1,1,0-31.9H47.9a15.9,15.9,0,0,1,16,15.9v95.9A16,16,0,0,1,47.9,138.5Z'/%3E%3Cpath d='M474,101.2H143.8a16,16,0,0,1,0-32H474a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M474,271.6H143.8a16,16,0,1,1,0-31.9H474a16,16,0,1,1,0,31.9Z'/%3E%3Cpath d='M474,442.1H143.8a16,16,0,0,1,0-32H474a16,16,0,0,1,0,32Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-undo::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Ctitle%3Eundo%3C/title%3E%3Cpath d='M154.7,213.3H16a16,16,0,0,1-16-16V58.7a16,16,0,0,1,32,0V181.3H154.7a16,16,0,0,1,0,32Z'/%3E%3Cpath d='M256,512A254.1,254.1,0,0,1,75,437a16.1,16.1,0,1,1,22.7-22.7A222.1,222.1,0,0,0,256,480c123.5,0,224-100.5,224-224S379.5,32,256,32C150.1,32,55.7,103.1,31.6,201.2A16,16,0,0,1,.5,193.5C28.2,81.4,135.6,0,256,0,397.2,0,512,114.8,512,256S397.2,512,256,512Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-redo::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Ctitle%3Eredo%3C/title%3E%3Cpath d='M496,213.3H357.3a16,16,0,0,1,0-32H480V58.7a16,16,0,0,1,32,0V197.3A16,16,0,0,1,496,213.3Z'/%3E%3Cpath d='M256,512C114.8,512,0,397.2,0,256S114.8,0,256,0C376.4,0,483.8,81.4,511.5,193.5a16,16,0,1,1-31.1,7.7C456.2,103.2,361.8,32,256,32,132.5,32,32,132.5,32,256S132.5,480,256,480a222.4,222.4,0,0,0,158.3-65.6A16,16,0,0,1,437,437,254.3,254.3,0,0,1,256,512Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M432 64H80a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 416H80a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 152H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 240H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 328H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M142.3 207.2a16 16 0 0 0-28.6-14.4A93.5 93.5 0 0 1 96 217.3L68.7 244.7a15.9 15.9 0 0 0 0 22.6L96 294.7a93.5 93.5 0 0 1 17.7 24.5A15.9 15.9 0 0 0 128 328a15.5 15.5 0 0 0 7.2-1.7 15.9 15.9 0 0 0 7.1-21.5A127.3 127.3 0 0 0 118.7 272H208a16 16 0 0 0 0-32H118.7a127.3 127.3 0 0 0 23.6-32.8Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M80 96H432a16 16 0 0 0 0-32H80a16 16 0 0 0 0 32Z'/%3E%3Cpath d='M432 416H80a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 152H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 240H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M432 328H272a16 16 0 0 0 0 32H432a16 16 0 0 0 0-32Z'/%3E%3Cpath d='M80 272h89.3a127.3 127.3 0 0 0-23.6 32.8 15.9 15.9 0 0 0 7.1 21.5A15.5 15.5 0 0 0 160 328a15.9 15.9 0 0 0 14.3-8.8A91.3 91.3 0 0 1 192 294.7l27.3-27.4a15.9 15.9 0 0 0 0-22.6L192 217.3a91.3 91.3 0 0 1-17.7-24.5 16 16 0 1 0-28.6 14.4A129.1 129.1 0 0 0 169.3 240H80a16 16 0 0 0 0 32Z'/%3E%3C/svg%3E");
}

trix-toolbar .trix-dialogs {
  position: relative;
}

trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5;
}

trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #f00 0px 0px 1.5px 1px;
}

trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none;
}

trix-toolbar .trix-dialog--link {
  max-width: 600px;
}

trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline;
}

trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1;
}

trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0;
}

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection,
trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection,
trix-editor [data-trix-mutable] ::selection {
  background: none;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}

trix-editor .attachment {
  position: relative;
}

trix-editor .attachment:hover {
  cursor: default;
}

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}

trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}

trix-editor .trix-button-group {
  display: inline-flex;
}

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}

trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-editor .trix-button.trix-active {
  background: #cbeefa;
}

trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}

trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}

trix-editor .trix-button--remove:hover {
  border-color: #333;
}

trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}

trix-editor .attachment__metadata-container {
  position: relative;
}

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}

trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}

.trix-content {
  line-height: 1.8;
  font-size: calc(1rem + 0.1vmax);
}

.trix-content * {
  box-sizing: border-box;
}

.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.8;
  margin: 0;
}

.trix-content blockquote {
  margin: 0 0 0 0.3em;
  padding: 0 0 0 0.6em;
  border-left: 0.3em solid #ccc;
}

.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  margin: 0;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}

.trix-content ul,
.trix-content ol,
.trix-content li {
  margin: 0;
  padding: 0;
}

.trix-content ul li,
.trix-content ol li,
.trix-content li li {
  margin-left: 1em;
}

.trix-content img {
  max-width: 100%;
  height: auto;
}

.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.trix-content a {
  transition: all 0.3s ease;
  background-color: #d6c6f34d;
  border-bottom: 1px solid #00000033;
  color: #1a1a1a;
}

.trix-content a:hover {
  background-color: #d6c6f3;
  border-bottom-color: #1a1a1a;
}

.trix-content .attachment a {
  color: inherit;
  text-decoration: none;
}

.trix-content .attachment a:hover,
.trix-content .attachment a:visited:hover {
  color: inherit;
}

.trix-content .attachment__caption {
  padding: 0;
  text-align: center;
}

.trix-content .attachment__caption .attachment__name + .attachment__size::before {
  content: " · ";
}

.trix-content .attachment--preview {
  width: 100%;
  text-align: center;
}

.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2;
}

.trix-content .attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 0;
  padding: 0.4em 1em;
  border: 1px solid #bbb;
  border-radius: 5px;
}

.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  padding: 0;
}

.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 .attachment,
.trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%;
}
