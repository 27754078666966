.slider {
	height: 100%;
}

.slider__container {
	position: relative;
	height: 100%;
}

.slider__item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-origin: right center;
	/* will-change: transform, opacity; */
}

.slider__inner {
	width: 100%;
	height: 100%;
	will-change: box-shadow;
	border-radius: 16px;
	overflow: hidden;
	background: #ffffff;
	box-shadow: 0 0 1px 1px #00000020, 0 10px 10px -2.5px #00000020;
	transform: translate3d(0, 0, 0);
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.events-screen {
	height: 100%;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.events-screen__slider {
	position: relative;
	width: 100%;
	flex: 1;
}
