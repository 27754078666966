.video-js,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-fluid {
  font-family: inherit;
  overflow: hidden;
  /* border-radius: 8px; */
}

.video-js .vjs-icon-play:before,
.video-js .vjs-big-play-button:before {
  font-size: 40px;
}

.video-js .vjs-progress-holder {
  font-size: 1.7em;
  border-radius: 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress,
.video-js .vjs-progress-holder .vjs-load-progress,
.video-js .vjs-progress-holder .vjs-load-progress div,
.video-js .vjs-slider,
.vjs-volume-level {
  border-radius: 10px;
}

.video-js .vjs-load-progress {
  background: rgba(255, 255, 255, 0.5);
}

.video-js,
.video-js video,
.vjs-poster,
.video-js .vjs-tech {
  /* border-radius: 8px; */
}

.vjs-menu,
.vjs-menu li {
  font-family: inherit !important;
  font-size: calc(14px + 0.14vw);
}

.vjs-text-track-cue {
  font: calc(14px + 0.6vw) "Satoshi";
}

.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em;
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-control {
  width: 3em;
}

.video-js .vjs-menu-button-inline:before {
  width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em;
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button {
  display: block;
}

.video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
  padding: 0 0.4em 0.3em;
}

.video-js.vjs-ended .vjs-loading-spinner {
  display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important;
}

video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
  display: block;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-js .vjs-big-play-button {
  height: 2em !important;
  width: 2em !important;
  line-height: 1.7em !important;
  margin-top: 0 !important;
  margin-left: 0;
  border-width: 4px;
  background-color: #005ee9;
  font-size: 4em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 1.75em !important;
  transition: all 0.3s ease-in-out;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus,
.video-js .vjs-big-play-button:active {
  background-color: #005ee9;
}

.video-js .vjs-loading-spinner {
  border-color: #005ee9;
}

.video-js .vjs-control-bar2 {
  background-color: transparent;
}

.video-js .vjs-control-bar {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25)) !important;
  color: #ffffff;
  font-size: 17px;
}

.video-js .vjs-play-progress,
.video-js .vjs-volume-level {
  background-color: #005ee9;
}

.video-js .vjs-icon-play:before,
.video-js .vjs-big-play-button:before {
  font-size: 40px;
}

.video-js .vjs-progress-holder {
  font-size: 1.7em;
  border-radius: 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress,
.video-js .vjs-progress-holder .vjs-load-progress,
.video-js .vjs-progress-holder .vjs-load-progress div,
.video-js .vjs-slider,
.vjs-volume-level {
  border-radius: 10px;
}

.video-js .vjs-load-progress {
  background: rgba(255, 255, 255, 0.5);
}

.video-js,
.video-js video,
.vjs-poster,
.video-js .vjs-tech {
  /* border-radius: 8px; */
}

.video-js .vjs-time-control {
  padding-left: unset;
}
